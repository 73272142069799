// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { concatMap, map } from 'rxjs/operators';
import type {
  GetDemographicsHistoryResponse,
  Activity,
  GetActivityHistoryResponse,
  GetPatientResponse,
  Patient,
  PatientSearchResponse,
  SearchHl7MessagesResponse,
  Hl7MessageSummary,
} from '@imprivata-cloud/adminapi-client';
import { from, type Observable } from 'rxjs';
import moment from 'moment-timezone';
import type { PatientSearchRequestDecrypted } from '../../containers/patient-search/types';
import type {
  PatientDemographicHistoryDecrypted,
  PatientDemographicHistoryItemDecrypted,
} from '../types';
import { formatUpdatedField, capitalizeIfString } from '../../utils/utils';
import {
  adminApiClient,
  getCodingContext,
  promiseToObservable,
} from '../client';
import {
  ActivityDecrypted,
  ActivityHistoryResponseDecrypted,
  IdentifierDecrypted,
  PatientDecrypted,
  PatientSearchResponseDecrypted,
  PatientHl7MessageSummaryDecrypted,
} from '../types';

export function searchPatient$(
  request: PatientSearchRequestDecrypted,
): Observable<PatientSearchResponseDecrypted> {
  return promiseToObservable(
    getCodingContext().then(async codingContext =>
      adminApiClient.searchForPatient({
        identifiers: request.identifiers?.map(x =>
          codingContext.encryptString(x),
        ),
        phoneNumbers: request.phoneNumbers?.map(x =>
          codingContext.encryptString(x),
        ),
        emailAddresses: request.emailAddresses?.map(x =>
          codingContext.encryptString(x),
        ),
        givenNames: request.givenNames?.map(x =>
          codingContext.encryptString(x),
        ),
        surname: codingContext.encryptString(request.surname),
      }),
    ),
  ).pipe(
    concatMap((response: PatientSearchResponse) => {
      return from(decryptPatientSearchResponse(response));
    }),
  );
}

export function getPatient$(id: string): Observable<PatientDecrypted> {
  return promiseToObservable(
    getCodingContext().then(_ => adminApiClient.getPatient(id)),
  ).pipe(
    concatMap(async (response: GetPatientResponse) => {
      return decryptGetPatientResponse(response);
    }),
  );
}

export function deletePatient$(id: string): Observable<string> {
  return promiseToObservable(adminApiClient.deletePatient(id)).pipe(
    map(() => {
      return id;
    }),
  );
}

export function getDemographicsHistory$(
  patientId: string,
): Observable<PatientDemographicHistoryDecrypted> {
  return promiseToObservable(
    adminApiClient.getDemographicsHistory(patientId),
  ).pipe(
    concatMap(async (response: GetDemographicsHistoryResponse) => {
      return decryptGetDemographicsHistoryResponse(response);
    }),
  );
}

export function getActivityHistory$(
  id: string,
): Observable<ActivityHistoryResponseDecrypted> {
  return promiseToObservable(adminApiClient.getActivityHistory(id)).pipe(
    concatMap(async (response: GetActivityHistoryResponse) => {
      return decryptGetActivityHistoryResponse(response);
    }),
  );
}


async function decryptGetDemographicsHistoryResponse(
  response: GetDemographicsHistoryResponse,
): Promise<PatientDemographicHistoryDecrypted> {
  return getCodingContext().then(codingContext => {
    const demographicsHistory = response.demographicsHistory;

    const decryptedHistory =
      demographicsHistory?.map<PatientDemographicHistoryItemDecrypted>(
        item => ({
          patientId: item.patientId ?? null,
          updatedField: formatUpdatedField(item.updatedField ?? '') ?? null,
          updatedTimestamp: item.updatedTimestamp
            ? moment(item.updatedTimestamp)
            : null,
          oldValue: capitalizeIfString(
            codingContext.decryptToString({
              data: item.oldValue?.data ?? '',
            }),
          ),
          newValue: capitalizeIfString(
            codingContext.decryptToString({
              data: item.newValue?.data ?? '',
            }),
          ),
          updatedBy: codingContext.decryptToString({
            data: item.updatedBy?.data ?? '',
          }),
        }),
      ) ?? null;

    // sort data by date from newest to oldest
    decryptedHistory?.sort((a, b) => {
      if (a.updatedTimestamp && b.updatedTimestamp) {
        return (b.updatedTimestamp as moment.Moment).diff(
          a.updatedTimestamp as moment.Moment,
        );
      }
      return 0;
    });

    return {
      demographicsHistory: decryptedHistory,
    };
  });
}

async function decryptPatientSearchResponse(
  response: PatientSearchResponse,
): Promise<PatientSearchResponseDecrypted> {
  return getCodingContext().then(
    codingContext =>
      new PatientSearchResponseDecrypted({
        patients: response.patients?.map<PatientDecrypted>(
          (patient: Patient) =>
            new PatientDecrypted({
              id: patient.id,
              givenNames: patient.givenNames?.map<string>(givenName =>
                codingContext.decryptToString({
                  data: givenName.data ?? '',
                }),
              ),
              surname: codingContext.decryptToString({
                data: patient.surname?.data ?? '',
              }),
              dateOfBirth: codingContext.decryptToString({
                data: patient?.dateOfBirth?.data ?? '',
              }),
              identifiers: patient.identifiers?.map<IdentifierDecrypted>(
                identifier =>
                  new IdentifierDecrypted({
                    system: identifier.system,
                    value: codingContext.decryptToString({
                      data: identifier.value?.data ?? '',
                    }),
                  }),
              ),
              phoneNumbers: patient.phoneNumbers?.map<string>(phoneNumber =>
                codingContext.decryptToString({
                  data: phoneNumber.data ?? '',
                }),
              ),
              emailAddresses: patient.emailAddresses?.map<string>(
                emailAddress =>
                  codingContext.decryptToString({
                    data: emailAddress.data ?? '',
                  }),
              ),
              createdTimestamp: patient.createdTimestamp,
              updatedTimestamp: patient.updatedTimestamp,
              createdAt: codingContext.decryptToString({
                data: patient.createdAt?.data ?? '',
              }),
              updatedAt: codingContext.decryptToString({
                data: patient.updatedAt?.data ?? '',
              }),
              createdBy: codingContext.decryptToString({
                data: patient.createdBy?.data ?? '',
              }),
              updatedBy: codingContext.decryptToString({
                data: patient.updatedBy?.data ?? '',
              }),
              photo: codingContext.decryptToString({
                data: patient.photo?.data ?? '',
              }),
              photoTakenDate: patient.photoTakenDate,
            }),
        ),
      }),
  );
}

async function decryptGetPatientResponse(
  response: GetPatientResponse,
): Promise<PatientDecrypted> {
  const patient = response.patient;
  return getCodingContext().then(
    codingContext =>
      new PatientDecrypted({
        id: patient?.id,
        givenNames: patient?.givenNames?.map<string>(givenName =>
          codingContext.decryptToString({
            data: givenName.data ?? '',
          }),
        ),
        surname: codingContext.decryptToString({
          data: patient?.surname?.data ?? '',
        }),
        dateOfBirth: codingContext.decryptToString({
          data: patient?.dateOfBirth?.data ?? '',
        }),
        identifiers: patient?.identifiers?.map<IdentifierDecrypted>(
          identifier =>
            new IdentifierDecrypted({
              system: identifier.system,
              value: codingContext.decryptToString({
                data: identifier.value?.data ?? '',
              }),
            }),
        ),
        phoneNumbers: patient?.phoneNumbers?.map<string>(phoneNumber =>
          codingContext.decryptToString({
            data: phoneNumber.data ?? '',
          }),
        ),
        emailAddresses: patient?.emailAddresses?.map<string>(emailAddress =>
          codingContext.decryptToString({
            data: emailAddress.data ?? '',
          }),
        ),
        createdTimestamp: patient?.createdTimestamp,
        updatedTimestamp: patient?.updatedTimestamp,
        createdAt: codingContext.decryptToString({
          data: patient?.createdAt?.data ?? '',
        }),
        updatedAt: codingContext.decryptToString({
          data: patient?.updatedAt?.data ?? '',
        }),
        createdBy: codingContext.decryptToString({
          data: patient?.createdBy?.data ?? '',
        }),
        updatedBy: codingContext.decryptToString({
          data: patient?.updatedBy?.data ?? '',
        }),
        photo: codingContext.decryptToString({
          data: patient?.photo?.data ?? '',
        }),
        photoTakenDate: patient?.photoTakenDate,
      }),
  );
}

async function decryptGetActivityHistoryResponse(
  response: GetActivityHistoryResponse,
): Promise<ActivityHistoryResponseDecrypted> {
  return getCodingContext().then(
    codingContext =>
      new ActivityHistoryResponseDecrypted({
        activities: response.activityHistory?.map<ActivityDecrypted>(
          (activity: Activity) =>
            new ActivityDecrypted({
              date: activity.date,
              activityType: activity.activityType,
              activitySubtype: activity.activitySubtype,
              createdBy: codingContext.decryptToString({
                data: activity.createdBy?.data ?? '',
              }),
              createdAt: codingContext.decryptToString({
                data: activity.createdAt?.data ?? '',
              }),
            }),
        ),
      }),
  );
}
