// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useSelector } from 'react-redux';
import {
  isLoadingDemographicsHistorySelector,
  isPatientSearchingSelector,
  patientActivityHistorySelector,
  patientSearchErrorSelector,
  patientSearchResponseSelector,
  patientSelectedSelector,
  demographicUpdatesSelector,
  isLoadingActivityHistorySelector,
  isLoadingHl7MessagesSelector,
} from './selectors';

export const usePatientSearch = (): {
  searchResponse: ReturnType<typeof patientSearchResponseSelector>;
  isSearching: ReturnType<typeof isPatientSearchingSelector>;
  error: ReturnType<typeof patientSearchErrorSelector>;
  selectedPatient: ReturnType<typeof patientSelectedSelector>;
  demographicUpdates: ReturnType<typeof demographicUpdatesSelector>;
  patientActivities: ReturnType<typeof patientActivityHistorySelector>;
  isLoadingDemographicsHistory: ReturnType<
    typeof isLoadingDemographicsHistorySelector
  >;
  isLoadingActivityHistory: ReturnType<typeof isLoadingActivityHistorySelector>;
  isLoadingHl7Messages: ReturnType<typeof isLoadingHl7MessagesSelector>;
} => {
  const searchResponse = useSelector(patientSearchResponseSelector);
  const isSearching = useSelector(isPatientSearchingSelector);
  const error = useSelector(patientSearchErrorSelector);
  const selectedPatient = useSelector(patientSelectedSelector);
  const demographicUpdates = useSelector(demographicUpdatesSelector);
  const patientActivities = useSelector(patientActivityHistorySelector);
  const isLoadingDemographicsHistory = useSelector(
    isLoadingDemographicsHistorySelector,
  );
  const isLoadingActivityHistory = useSelector(isLoadingActivityHistorySelector);
  const isLoadingHl7Messages = useSelector(isLoadingHl7MessagesSelector);
  return {
    searchResponse,
    isSearching,
    error,
    selectedPatient,
    demographicUpdates,
    patientActivities,
    isLoadingDemographicsHistory,
    isLoadingActivityHistory,
    isLoadingHl7Messages,
  };
};
