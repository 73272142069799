// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { RootAction } from '../../../store/rootAction';
import {
  clearPatientDeleteAction,
  patientDeleteActions,
  updatePaginationAction,
} from './actions';
import {
  fetchDemographicsHistoryActions,
  patientActivityHistoryActions,
  patientHl7MessagesActions,
  patientSearchActions,
  selectPatientAction,
} from '../../patient-search/store/actions';
import { invalidSessionAction } from '../../login/store/actions';

export interface PatientDetailsState {
  deletionError: string | null;
  isDeletionOccurred: boolean;
  pagination: PatientDetailsPagination;
}

export interface PatientDetailsPagination {
  activeTab: string;
  activities: PaginationDetails;
  demographicUpdates: PaginationDetails;
  hl7Messages: PaginationDetails;
}

interface PaginationDetails {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export const initialState: PatientDetailsState = {
  deletionError: null,
  isDeletionOccurred: false,
  pagination: {
    activeTab: '1',
    activities: {
      pageNumber: 1,
      pageSize: 25,
      totalCount: 0,
    },
    demographicUpdates: {
      pageNumber: 1,
      pageSize: 25,
      totalCount: 0,
    },
    hl7Messages: {
      pageNumber: 1,
      pageSize: 25,
      totalCount: 0,
    },
  },
};

export const patientDetailsReducer = combineReducers<PatientDetailsState>({
  deletionError: createReducer<string | null, RootAction>(
    initialState.deletionError,
  )
    .handleAction(
      [
        patientDeleteActions.request,
        patientDeleteActions.success,
        invalidSessionAction.request,
        clearPatientDeleteAction,
      ],
      () => null,
    )
    .handleAction(
      [patientDeleteActions.failure],
      (_, { payload }) => payload.code || null,
    )
    .handleAction([patientDeleteActions.cancel], () => 'cancelled'),
  isDeletionOccurred: createReducer<boolean, RootAction>(
    initialState.isDeletionOccurred,
  )
    .handleAction(
      [patientDeleteActions.success, patientDeleteActions.failure],
      () => true,
    )
    .handleAction(
      [
        patientSearchActions.request,
        selectPatientAction,
        invalidSessionAction.request,
        clearPatientDeleteAction,
      ],
      () => false,
    ),
  pagination: createReducer<PatientDetailsPagination, RootAction>(
    initialState.pagination,
  )
    .handleAction(updatePaginationAction, (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    })
    .handleAction(selectPatientAction, () => initialState.pagination)
    .handleAction(patientActivityHistoryActions.success, (state, action) => {
      return {
        ...state,
        activities: {
          ...state.activities,
          totalCount: action.payload.activities?.length || 0,
        },
      };
    })
    .handleAction(fetchDemographicsHistoryActions.success, (state, action) => {
      return {
        ...state,
        demographicUpdates: {
          ...state.demographicUpdates,
          totalCount: action.payload.demographicsHistory?.length || 0,
        },
      };
    })
    .handleAction(patientHl7MessagesActions.success, (state, action) => {
      return {
        ...state,
        hl7Messages: {
          ...state.hl7Messages,
          totalCount: action.payload?.length || 0,
        },
      };
    }),
});
