// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';

import classes from './ContentCard.module.less';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const ContentCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> =
  function ContentCard({ className, children, ...rest }) {
    return (
      <div className={clsx(className, classes.contentCard)} {...rest}>
        {children}
      </div>
    );
  };

export default ContentCard;
