// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import PageLayout from '../../components/page-layout/PageLayout';
import classes from './Reports.module.less';
import pageLayoutClasses from '../../components/page-layout/PageLayout.module.less';

import ReportsContextProvider from './components/reports-context-provider/ReportsContextProvider';
import ReportsRoutes from './ReportsRoutes';

const { Content } = Layout;

const Reports: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      title={t('navigation.reports')}
      className={classes.reports}
      contentClassName={classes.content}
    >
      <ReportsContextProvider>
        <Layout className={classes.layout}>
          <Content className={pageLayoutClasses.pageContent}>
            <ReportsRoutes />
          </Content>
        </Layout>
      </ReportsContextProvider>
    </PageLayout>
  );
};

export default Reports;
