// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import type { ActionButtonProps } from './types';
import cancelIcon from '../../assets/svg/cancelIcon.svg?react';
import clsx from 'clsx';

const CancelButton: React.FC<ActionButtonProps> = function CancelButton({
  onClick,
  className,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Button
      label={t('actions.cancel')}
      data-testid="cancel-button"
      icon={<Icon component={cancelIcon} />}
      className={clsx(className, 'secondary')}
      variant={ButtonVariant.SECONDARY}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default CancelButton;
