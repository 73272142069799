// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { ContextNames } from '.';

export function getErrorMessageCode(
  context: ContextNames,
  errorCode: string,
): string {
  switch (errorCode) {
    case 'invalid-credentials':
      if (context === ContextNames.LOGIN) {
        return 'user-invalid-credentials';
      } else {
        return errorCode;
      }
    case 'session-expired':
      if (context === ContextNames.LOGIN) {
        return 'session-expired';
      } else {
        return errorCode;
      }
    case 'not-found':
      if (context === ContextNames.LOGIN) {
        return 'user-not-found';
      } else {
        return errorCode;
      }
    default:
      return errorCode;
  }
}
