// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/page-layout/PageLayout';
import IntegrationsContextProvider from './components/integrations-context-provider/IntegrationsContextProvider';

import classes from './Settings.module.less';
import SetTitle from '../../utils/DynamicTitleHelper';
import pageLayoutClasses from '../../components/page-layout/PageLayout.module.less';
import AgeLimitConfigurationComponent from './containers/age-limit-configuration/AgeLimitConfigurationContainer';

const { Content } = Layout;

const Settings: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.settings'));

  return (
    <PageLayout
      title={t('navigation.settings')}
      className={classes.integrations}
      contentClassName={classes.content}
    >
      <IntegrationsContextProvider>
        <Layout className={classes.layout}>
          <Content className={pageLayoutClasses.pageContent}>
            <AgeLimitConfigurationComponent />
          </Content>
        </Layout>
      </IntegrationsContextProvider>
    </PageLayout>
  );
};

export default Settings;
