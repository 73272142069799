// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { FormEvent } from 'react';

export function copyToClipboard(e: FormEvent, content: string): void {
  e.preventDefault();
  navigator.clipboard.writeText(content);
}

export function formatUpdatedField(text: string): string {
  switch (text) {
    case 'DOB':
      return 'Date of Birth';
    case 'GivenNames':
      return 'Given Name';
    default:
      if (text.startsWith('urn:oid')) {
        return 'MRN';
      } else {
        return text;
      }
  }
}

export const capitalizeIfString = (value: string): string => {
  if (!isNaN(Number(value))) {
    return value;
  }
  return value
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export function formatActivitySubtype(subtype: string): string {
  if (subtype === "MultipleMatchesSuccess") {
    return "Multiple matches success";
  }
  return subtype;
}
