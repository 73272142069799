// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Menu, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import classes from './NavMenu.module.less';
import { useHistory } from 'react-router-dom';
import {
  getActiveItemFromPath,
  useSiteLayoutNavigation,
} from '../../containers/site-layout/store/hooks';
import { DASHBOARD_ROUTE } from '../../routers/route-names';
import RoundedDownArrowSvg from '../../assets/svg/rounded-down-arrow.svg';
import { MenuItemKeys } from './MenuItemKeys';

interface NavMenu {
  headerProps?: React.ComponentProps<typeof PageHeader>;
  menuProps?: React.ComponentProps<typeof Menu>;
}

const NavMenu: React.FC<NavMenu> = function HeaderMenu({
  headerProps = {},
  menuProps = {},
}) {
  const { t } = useTranslation();
  const { ...restHeaderProps } = headerProps;
  const { mode, className: menuClassName, ...restMenuProps } = menuProps;

  const items: MenuProps['items'] = [
    {
      className: classes.menuItem,
      key: MenuItemKeys.Dashboard,
      label: (
        <button
          className={classes.navButton}
          data-testid="nav-menu-button--dashboard"
        >
          {t('navigation.dashboard').toUpperCase()}
        </button>
      ),
    },
    {
      className: classes.menuItem,
      key: MenuItemKeys.Patients,
      label: (
        <button
          className={classes.navButton}
          data-testid="nav-menu-button--patients"
        >
          {t('navigation.patients').toUpperCase()}
        </button>
      ),
    },
    {
      className: classes.menuItem,
      key: MenuItemKeys.Reports,
      label: (
        <button
          className={classes.navButton}
          data-testid="nav-menu-button--reports"
        >
          <span className={classes.navButtonText}>
            {t('navigation.reports').toUpperCase()}
          </span>
          <img
            src={RoundedDownArrowSvg}
            alt={t('navigation.reports--alt')}
            className={classes.navButtonIcon}
          />
        </button>
      ),
      children: [
        {
          className: classes.subMenuItem,
          label: (
            <button className={classes.subMenuItemButton}>
              {t('navigation.activity-extract')}
            </button>
          ),
          key: MenuItemKeys.ActivityExtract,
        },
        {
          className: classes.subMenuItem,
          label: (
            <button className={classes.subMenuItemButton}>
              {t('navigation.audit-extract')}
            </button>
          ),
          key: MenuItemKeys.AuditExtract,
        },
      ],
    },
    {
      className: classes.menuItem,
      key: MenuItemKeys.Installers,
      label: (
        <button
          className={classes.navButton}
          data-testid="nav-menu-button--installers"
        >
          {t('navigation.installers').toUpperCase()}
        </button>
      ),
    },
    {
      className: classes.menuItem,
      key: MenuItemKeys.Integrations,
      label: (
        <button
          className={classes.navButton}
          data-testid="nav-menu-button--integrations"
        >
          <span className={classes.navButtonText}>
            {t('navigation.integrations').toUpperCase()}
          </span>
          <img
            src={RoundedDownArrowSvg}
            alt={t('navigation.integrations--alt')}
            className={classes.navButtonIcon}
          />
        </button>
      ),
      children: [
        {
          className: classes.subMenuItem,
          label: (
            <button className={classes.subMenuItemButton}>
              {t('navigation.fhir-configuration')}
            </button>
          ),
          key: MenuItemKeys.FhirConfiguration,
        },
        {
          className: classes.subMenuItem,
          label: (
            <button className={classes.subMenuItemButton}>
              {t('navigation.hl7-configuration')}
            </button>
          ),
          key: MenuItemKeys.Hl7Configuration,
        },
      ],
    },
  ];

  const [activeMenuItem, setActiveMenuItem, handleItemClick] =
    useSiteLayoutNavigation();

  const onMenuClick = useCallback(
    (menuInfo: { key: string }) => handleItemClick(menuInfo.key),
    [handleItemClick],
  );

  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP' || history.action === 'PUSH') {
        setActiveMenuItem(
          getActiveItemFromPath(location.pathname) ?? DASHBOARD_ROUTE,
        );
      }
    });
  }, [activeMenuItem, setActiveMenuItem, history, onMenuClick]);

  return (
    <PageHeader data-testid="side-menu" {...restHeaderProps}>
      <Menu
        theme="dark"
        mode="horizontal"
        className={clsx(classes.menu, menuClassName)}
        items={items}
        {...restMenuProps}
      />
    </PageHeader>
  );
};

export default NavMenu;
