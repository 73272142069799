// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Icon from '@ant-design/icons';
import type { RangeValue } from 'rc-picker/lib/interface';
import type { MenuItemType } from 'rc-menu/lib/interface';
import PageSubHeader from '../../../../../../components/page-sub-header/PageSubHeader';
import Hl7MessageRow from '../../components/hl7-message-row/Hl7MessageRow';
import classes from './Hl7MessagesContainer.module.less';
import { useHl7MessageSearch } from '../../store/hooks';
import { getHl7Message } from '../../store/facades';
import PaginationControls from '../../../../../patient-details/components/general/PaginationControls';
import smallDownArrow from '../../../../../../assets/svg/arrow-60-down.svg?react';
import { integrationsRoutes } from '../../../../../../routers/route-names';
import { getPathWithQuery } from '../../../../../../utils/routingHelpers';
import { Link } from 'react-router-dom';
import ContentCard from '../../../../../../components/content-card/ContentCard';
import { useTranslation } from 'react-i18next';
import SetTitle from '../../../../../../utils/DynamicTitleHelper';
const { RangePicker } = DatePicker;

const Hl7MessagesComponent: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = useState<string>();

  const { t } = useTranslation();

  SetTitle(t('hl7-configuration.messages.title'));

  const pageSizes: MenuItemType[] = [
    {
      key: '10',
      label: 10,
      onClick: () => {
        onPageSizeChanged(10);
      },
    },
    {
      key: '25',
      label: 25,
      onClick: () => {
        onPageSizeChanged(25);
      },
    },
    {
      key: '50',
      label: 50,
      onClick: () => {
        onPageSizeChanged(50);
      },
    },
    {
      key: '100',
      label: 100,
      onClick: () => {
        onPageSizeChanged(100);
      },
    },
  ];

  const statusSelections: MenuItemType[] = [
    {
      key: '1',
      label: 'All',
      onClick: () => {
        onStatusSelection('All', []);
      },
    },
    {
      key: '2',
      label: 'Error',
      onClick: () => {
        onStatusSelection('Error', ['error']);
      },
    },
    {
      key: '3',
      label: 'Success',
      onClick: () => {
        onStatusSelection('Success', ['success']);
      },
    },
    {
      key: '4',
      label: 'Unsent',
      onClick: () => {
        onStatusSelection('Unsent', ['unsent']);
      },
    },
  ];

  const onPageSizeChanged = (pageSize: number) => {
    updateSearchFilters({
      ...filters,
      pageSize,
      pageNumber: 1,
    });
  };

  const onStatusSelection = (label: string, value: string[]) => {
    setSelectedStatus(label);
    updateSearchFilters({
      ...filters,
      statuses: value,
      pageNumber: 1,
    });
  };

  const onDateRangeChanged = (values: RangeValue<moment.Moment>) => {
    if (!values) {
      return;
    }

    const startDate = values[0];
    const endDate = values[1];

    if (startDate && endDate) {
      updateSearchFilters({
        ...filters,
        dateRange: {
          startDate,
          endDate,
        },
        pageNumber: 1,
      });
    }
  };

  const goToNextPage = () => {
    updateSearchFilters({
      ...filters,
      pageNumber: filters.pageNumber + 1,
    });
  };

  const goToPreviousPage = () => {
    updateSearchFilters({
      ...filters,
      pageNumber: filters.pageNumber - 1,
    });
  };

  const { results, isLoading, counts, updateSearchFilters, filters } =
    useHl7MessageSearch();

  useEffect(() => {
    if (!filters.statuses.length || filters.statuses.length === 3) {
      setSelectedStatus('All');
    } else {
      let selectedStatus;
      if (filters.statuses.includes('success')) selectedStatus = 'Success';
      else if (filters.statuses.includes('error')) selectedStatus = 'Error';
      else selectedStatus = 'Unsent';
      setSelectedStatus(selectedStatus);
    }
  }, [filters]);

  const loadHl7Message = (id: string) => {
    getHl7Message(id, dispatch);
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: '10px' }}>
        <Breadcrumb.Item>
          <Link to={getPathWithQuery(integrationsRoutes.HL7_CONFIGURATION)}>
            {t('navigation.hl7-configuration')}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {t('hl7-configuration.messages.title')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageSubHeader title={t('hl7-configuration.messages.title')} />
      <ContentCard>
        <Row className={classes.tableOptions}>
          <Col>
            <Space>
              <Dropdown menu={{ items: statusSelections }}>
                <Button>
                  <Typography.Text strong>
                    Status ({selectedStatus})
                  </Typography.Text>
                  <Icon component={smallDownArrow} />
                </Button>
              </Dropdown>
              <RangePicker
                data-testid="hl7-messages--date-range-value-input"
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                defaultValue={[
                  filters.dateRange.startDate,
                  filters.dateRange.endDate,
                ]}
                ranges={{
                  Today: [moment().startOf('day'), moment()],
                  Yesterday: [
                    moment().subtract(1, 'day').startOf('day'),
                    moment().subtract(1, 'day').endOf('day'),
                  ],
                  'Last 7 Days': [moment().subtract(7, 'days'), moment()],
                  'Last 30 Days': [moment().subtract(30, 'days'), moment()],
                }}
                onChange={onDateRangeChanged}
              />
            </Space>
          </Col>
          <PaginationControls
            pageSizes={pageSizes}
            currentCount={counts}
            pageSize={filters.pageSize}
            pageNumber={filters.pageNumber}
            goToPreviousPage={goToPreviousPage}
            goToNextPage={goToNextPage}
          />
        </Row>
        {isLoading ? (
          <Row justify="center">
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        ) : results.length === 0 ? (
          <Row className={classes.messageRow} justify="center">
            <Col flex="auto">
              <Typography.Title level={3} className={classes.textCenter}>
                No HL7 messages found
              </Typography.Title>
              <p className={classes.textCenter}>
                Adjust filters or check integration settings.
              </p>
            </Col>
          </Row>
        ) : (
          results.map(message => {
            return (
              <Row key={message.id} className={classes.messageRow}>
                <Col flex="auto">
                  <Hl7MessageRow
                    id={message.id ?? ''}
                    status={message.messageStatus ?? ''}
                    timestamp={
                      message.createdTimestamp === undefined
                        ? moment()
                        : message.createdTimestamp
                    }
                    eventDisplayName={message.eventDisplayName ?? ''}
                    onLoadMessageDetails={loadHl7Message}
                    messageDetails={message.details}
                  ></Hl7MessageRow>
                </Col>
              </Row>
            );
          })
        )}
      </ContentCard>
    </>
  );
};

export default Hl7MessagesComponent;
