// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import { TenantType } from '@imprivata-cloud/adminapi-client';
import type { RootAction } from '../../../store/rootAction';
import { QueryParamKey, StorageKeys } from '../constants';
import { getFromStorage } from '../../../utils';
import { setHeader } from '../../../api/api';
import { Headers } from '../../../api/constants';
import {
  invalidSessionAction,
  logoutAction,
  stateSaveUsernameAction,
  getTenantTypeAction,
  startClientUserSessionAction,
} from './actions';

export interface LoginState {
  tenantId?: string;
  tenantType?: TenantType;
  username: string;
  hasValidSession?: boolean;
  startingClientUserSession: boolean;
  permissionError?: string;
  storedURL?: string;
  preloginMessageKey?: string;
}

export const initialState: LoginState = {
  hasValidSession: undefined,
  tenantId: undefined,
  tenantType: undefined,
  username: '',
  startingClientUserSession: false,
};

export function rehydrate(): LoginState {
  // retrieve auth info from storage
  let storedTenantId = getFromStorage(StorageKeys.TENANT_ID) ?? undefined;
  const storedTenantType = getFromStorage(StorageKeys.TENANT_TYPE) ?? undefined;
  const storedUsername = getFromStorage(StorageKeys.USERNAME) ?? undefined;
  const urlTenantId = new URL(window.location.href).searchParams.get(
    QueryParamKey.TENANT_ID,
  );

  if (storedTenantId !== urlTenantId) {
    storedTenantId = undefined;
  }

  const storedHasValidSession =
    (storedTenantId && getFromStorage(StorageKeys.HAS_VALID_SESSION)) ||
    undefined;

  if (storedTenantId) {
    setHeader(Headers.ImprTenantId, storedTenantId);
  }

  let tenantTypeValue: TenantType | undefined;
  if (
    storedHasValidSession &&
    storedTenantType &&
    Object.values<string>(TenantType).includes(storedTenantType)
  ) {
    tenantTypeValue = storedTenantType as TenantType;
  } else {
    tenantTypeValue = undefined;
  }

  return {
    ...initialState,
    tenantId: storedTenantId,
    tenantType: tenantTypeValue,
    hasValidSession: !!storedHasValidSession,
    username: storedUsername ?? '',
  };
}

export const loginReducer = createReducer<LoginState, RootAction>(initialState)
  .handleAction(stateSaveUsernameAction, (state, { payload: username }) => {
    return {
      ...state,
      username,
      preloginMessageKey: undefined,
      permissionError: undefined,
    };
  })
  .handleAction(
    getTenantTypeAction.success,
    (state, { payload: TenantType }) => {
      return {
        ...state,
        tenantType: TenantType,
      };
    },
  )
  .handleAction(logoutAction.success, state => {
    const { tenantId } = state;
    // preserves tenantId so user can be redirected
    // back to login page without typing tenantId again
    return {
      ...initialState,
      tenantId,
    };
  })
  .handleAction(invalidSessionAction.success, (_, { payload }) => {
    const { storedURL, errorMsgKey } = payload;
    // preserves tenantId so user can be redirected
    // back to login page without typing tenantId again
    return {
      ...initialState,
      hasValidSession: undefined,
      storedURL,
      preloginMessageKey: errorMsgKey,
    };
  })
  .handleAction(startClientUserSessionAction.request, (state, { payload }) => {
    const tenantId = payload.tenantId || state.tenantId;
    return {
      ...state,
      tenantId,
      hasValidSession: undefined,
      startingClientUserSession: true,
    };
  })
  .handleAction(
    startClientUserSessionAction.success,
    (state, { payload: hasValidSession }) => {
      return {
        ...state,
        hasValidSession,
        startingClientUserSession: false,
      };
    },
  )
  .handleAction(
    startClientUserSessionAction.failure,
    (state, { payload: permissionError }) => {
      return {
        ...state,
        hasValidSession: false,
        permissionError,
        startingClientUserSession: false,
      };
    },
  );
