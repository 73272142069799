// Copyright 2022, Imprivata, Inc.  All rights reserved.

export enum MenuItemKeys {
  Dashboard = 'dashboard',
  Patients = 'patients',
  Reports = 'reports',
  ActivityExtract = 'ActivityExtract',
  AuditExtract = 'AuditExtract',
  Installers = 'installers',
  FhirConfiguration = 'fhir',
  Hl7Configuration = 'Hl7',
  Integrations = 'integrations',
  Settings = 'settings',
  Logout = 'logout',
  Help = 'help',
}
