// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useCallback, useEffect } from 'react';

export function useKeyCallBack(
  keys: string[],
  callback: (ev: KeyboardEvent) => void,
): void {
  const runCallback = useCallback(
    (ev: KeyboardEvent): void => {
      if (keys.includes(ev.key)) {
        callback(ev);
      }
    },
    [keys, callback],
  );

  useEffect(() => {
    document.addEventListener('keyup', runCallback);
    return () => {
      document.removeEventListener('keyup', runCallback);
    };
  }, [runCallback]);
}
