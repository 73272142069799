// Copyright 2022, Imprivata, Inc.  All rights reserved.

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

// Import into scope early
import { i18nAuthn as _ } from '@imprivata-cloud/authn';
import { LocaleProvider } from './i18n';

import history from './routers/history';
import { configureStore } from './store/createStore';
import { rehydrate } from './store/rootReducer';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { applyInterceptors } from './api/interceptors';

import './styles/index.less';

const store = configureStore({
  isProduction: process.env.NODE_ENV === 'production',
  history,
  initialState: rehydrate(),
});
applyInterceptors(store.dispatch);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <LocaleProvider>
        <Router history={history}>
          <App />
        </Router>
      </LocaleProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(
  process.env.NODE_ENV === 'production' ? undefined : console.debug,
);
