// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Space, Typography } from 'antd';
import Layout from 'antd/lib/layout/layout';
import Icon from '@ant-design/icons';
import copyIcon from '../../assets/svg/copy.svg?react';
import successIcon from '../../assets/svg/success.svg?react';
import DownloadSvg from '../../assets/svg/download.svg';
import Link from 'antd/lib/typography/Link';
import type { GetEndpointInstallationDataResponse } from '@imprivata-cloud/adminapi-client';
import type { FormEvent } from 'react';
import classes from './EndpointInstaller.module.less';
import { copyToClipboard } from '../../utils/utils';
import SetTitle from '../../utils/DynamicTitleHelper';
import PageLayout from '../../components/page-layout/PageLayout';
import { getEndpointInstallerData$ } from '../../api/services/endpointInstallerService';
import {
  endGetEndpointInstallerDataSpan,
  startGetEndpointInstallerDataSpan,
} from './tracing';
import ContentCard from '../../components/content-card/ContentCard';
import PageSubHeader from '../../components/page-sub-header/PageSubHeader';
import { Button, ButtonVariant } from '@imprivata-cloud/components';

const EndpointInstaller: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.installers'));

  const [isInstallationKeyCopied, setIsInstallationKeyCopied] =
    useState<boolean>(false);
  const [isCommandLineCopied, setIsCommandLineCopied] =
    useState<boolean>(false);
  const [endpointInstallerData, setEndpointInstallerData] =
    useState<GetEndpointInstallationDataResponse | null>(null);

  const copyInstallInfo = (e: FormEvent) => {
    if (endpointInstallerData?.encodedInstallInfo) {
      copyToClipboard(e, endpointInstallerData.encodedInstallInfo);

      setIsInstallationKeyCopied(true);
      setTimeout(() => {
        setIsInstallationKeyCopied(false);
      }, 3000);
    }
  };

  const copyCommandLine = (e: FormEvent) => {
    if (endpointInstallerData?.commandLine) {
      copyToClipboard(e, endpointInstallerData.commandLine);

      setIsCommandLineCopied(true);
      setTimeout(() => {
        setIsCommandLineCopied(false);
      }, 3000);
    }
  };

  const useEndointInstallerDataMountEffect = () =>
    useEffect(() => {
      startGetEndpointInstallerDataSpan();
      getEndpointInstallerData$().then(response => {
        endGetEndpointInstallerDataSpan();
        setEndpointInstallerData(response);
      });
    }, []);
  useEndointInstallerDataMountEffect();

  return (
    <PageLayout title={t('navigation.installers')}>
      <Layout>
        <PageSubHeader title={t('navigation.installers')} />
        <ContentCard id={classes.installerContent}>
          <Typography.Title level={5} className={classes.installerTitle}>
            {t('installers.description')}
          </Typography.Title>
          <Space direction="vertical" className={classes.installerActions}>
            <Row align="middle">
              <Typography
                data-testid="installer--download-label"
                className={classes.installerActionLabel}
              >
                {t('installers.download-installer')}
              </Typography>
              <Link
                data-testid="installer--download-link"
                href={endpointInstallerData?.endpointInstallerUrl}
                className="impr-button secondary"
              >
                <img
                  src={DownloadSvg}
                  alt={t('installers.download-installer--button')}
                />
                <span>{t('installers.download-installer--button')}</span>
              </Link>
            </Row>
            <Row align="middle">
              <Typography
                data-testid="installer--copy-label"
                className={classes.installerActionLabel}
              >
                {t('installers.copy-key')}
              </Typography>
              <Button
                className="secondary"
                variant={ButtonVariant.SECONDARY}
                {...(!isInstallationKeyCopied && { onClick: copyInstallInfo })}
                icon={
                  <Icon
                    component={isInstallationKeyCopied ? successIcon : copyIcon}
                  />
                }
                label={
                  isInstallationKeyCopied
                    ? t('installers.copy--success')
                    : t('installers.copy')
                }
              />
            </Row>
            <Row align="middle">
              <Typography
                data-testid="command--copy-label"
                className={classes.installerActionLabel}
              >
                {t('installers.copy-command')}
              </Typography>
              <Button
                className="secondary"
                variant={ButtonVariant.SECONDARY}
                {...(!isCommandLineCopied && { onClick: copyCommandLine })}
                icon={
                  <Icon
                    component={isCommandLineCopied ? successIcon : copyIcon}
                  />
                }
                label={
                  isCommandLineCopied
                    ? t('installers.copy--success')
                    : t('installers.copy')
                }
              />
            </Row>
          </Space>
        </ContentCard>
      </Layout>
    </PageLayout>
  );
};

export default EndpointInstaller;
