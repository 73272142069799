// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, switchMap, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../../../i18n';
import {
  minimumAgeConfigurationGetActions,
  minimumAgeConfigurationSaveActions,
} from './actions';
import { getErrorMessageCode } from '../../../../../i18n/utils';
import {
  startGetMinimumAgeConfigurationSpan,
  endGetMinimumAgeConfigurationSpan,
  startSaveMinimumAgeConfigurationSpan,
  endSaveMinimumAgeConfigurationSpan,
} from '../../../tracing';
import { showErrorBannerAction } from '../../../../../store/error-banner-state/actions';
import { errors } from '../../../../../store/error-banner-state/errors';
import {
  getMinimumAgeConfiguration$,
  saveMinimumAgeConfiguration$,
} from '../../../../../api/services/ageLimitConfigurationService';

export const saveMinimumAgeConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(minimumAgeConfigurationSaveActions.request)),
    tap(() => {
      startSaveMinimumAgeConfigurationSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        saveMinimumAgeConfiguration$(payload.settingId, payload.newMinimumAge),
      ).pipe(
        tap(() => {
          endSaveMinimumAgeConfigurationSpan();
        }),
        map(() => minimumAgeConfigurationSaveActions.success()),
        catchError(error => {
          endSaveMinimumAgeConfigurationSpan(error);
          return of(
            minimumAgeConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.AGE_LIMIT_CONFIGURATION,
                'age-limit-configuration-save-failed',
              ),
              message:
                'An error occurred while saving the configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.AGE_LIMIT_MINIMUM_AGE_CONFIGURATION_SAVE_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const getMinimumAgeConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(minimumAgeConfigurationGetActions.request)),
    tap(() => {
      startGetMinimumAgeConfigurationSpan();
    }),
    switchMap(() => {
      return from(getMinimumAgeConfiguration$()).pipe(
        tap(() => {
          endGetMinimumAgeConfigurationSpan();
        }),
        map(minimumAgeConfiguration =>
          minimumAgeConfigurationGetActions.success(minimumAgeConfiguration),
        ),
        catchError(error => {
          endGetMinimumAgeConfigurationSpan(error);
          return of(
            minimumAgeConfigurationGetActions.failure({
              code: getErrorMessageCode(
                ContextNames.AGE_LIMIT_CONFIGURATION,
                'age-limit-configuration-get-failed',
              ),
              message:
                'An error occurred while retrieving the configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.AGE_LIMIT_MINIMUM_AGE_GET_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const ageLimitConfigurationEpic = combineEpics(
  saveMinimumAgeConfigurationEpic,
  getMinimumAgeConfigurationEpic,
);
