// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import {
  Banner,
  Button,
  ButtonVariant,
  InputBox,
} from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import type { KeyboardEvent } from 'react';
import PageLayout from '../../components/page-layout/PageLayout';
import SetTitle from '../../utils/DynamicTitleHelper';
import { patientSearch } from './store/facades';
import { usePatientSearch } from './store/hooks';
import classes from './PatientSearch.module.less';
import PatientSearchResults from './components/patient-search-results/PatientSearchResults';
import { usePatientDetails } from '../patient-details/store/hooks';
import { clearPatientSearchAction } from './store/actions';
import { PATIENT_DETAILS_ROUTE } from '../../routers/route-names';
import { clearPatientDeleteAction } from '../patient-details/store/actions';
import PageSubHeader from '../../components/page-sub-header/PageSubHeader';
import searchIcon from '../../assets/svg/search.svg?react';
import Icon from '@ant-design/icons';

const PatientSearch: React.FC = () => {
  const { t } = useTranslation();
  const [searchByIdentifierForm] = Form.useForm();
  const [searchByNameForm] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  SetTitle(t('patient-search.title'));

  const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);
  const { isSearching } = usePatientSearch();
  const { patientDeletionOccurred, deletionError } = usePatientDetails();
  const patientDeletionOccurredRef = useRef(patientDeletionOccurred);
  useEffect(() => {
    patientDeletionOccurredRef.current = patientDeletionOccurred;

    if (patientDeletionOccurred && !deletionError) {
      Banner({
        type: 'success',
        message: t('patient-details.delete-success'),
        duration: 5,
        datatestid: 'patient-delete--result',
      });
    }
  }, [deletionError, patientDeletionOccurred, t]);

  useEffect(() => {
    return () => {
      const nextPathname = history.location.pathname;
      if (nextPathname !== PATIENT_DETAILS_ROUTE) {
        dispatch(clearPatientSearchAction());
      }
    };
  }, [history, dispatch]);

  useEffect(() => {
    return () => {
      // sets patientDeletionOcurred to false only if there was a deletion
      if (patientDeletionOccurredRef.current) {
        dispatch(clearPatientDeleteAction());
      }
    };
  }, [dispatch]);

  const isIdentifierFormValid = async () => {
    try {
      await searchByIdentifierForm.validateFields();
      return true;
    } catch (err) {
      return false;
    }
  };

  const isNameFormValid = async () => {
    try {
      await searchByNameForm.validateFields();
      return true;
    } catch (err) {
      return false;
    }
  };

  const hasAValidForm = async () => {
    const formValidationResults = [
      await isIdentifierFormValid(),
      await isNameFormValid(),
    ];
    return formValidationResults.find(fvr => !!fvr);
  };

  const validateAndSearch = async () => {
    const identifierValueFieldValue =
      searchByIdentifierForm.getFieldValue('identifierValue') || '';

    const identifierValues =
      identifierValueFieldValue.length > 0
        ? [identifierValueFieldValue.trim()]
        : [];

    const patientGivenNameFieldValue =
      searchByNameForm.getFieldValue('patientGivenName') || '';
    const patientSurnameFieldValue =
      searchByNameForm.getFieldValue('patientSurname') || '';

    const givenNames =
      patientGivenNameFieldValue.length > 0
        ? patientGivenNameFieldValue.trim().split(' ')
        : [];
    const surname =
      patientSurnameFieldValue.length > 0
        ? patientSurnameFieldValue.trim()
        : '';

    searchByIdentifierForm.setFieldsValue({
      identifierValue: identifierValueFieldValue,
    });

    searchByNameForm.setFieldsValue({
      patientGivenName: patientGivenNameFieldValue,
      patientSurname: patientSurnameFieldValue,
    });

    if (!(await hasAValidForm())) {
      return;
    }

    resetEmptyFields();

    patientSearch(
      {
        identifiers: identifierValues,
        phoneNumbers: [],
        emailAddresses: [],
        givenNames: givenNames,
        surname: surname,
      },
      dispatch,
    );
  };

  const handleChange = async (formName: string) => {
    setIsSearchDisabled(!(await hasAValidForm()));
    resetEmptyFields(formName);
  };

  const handleInputKeyDown = async (event: KeyboardEvent<Element>) => {
    if (event.key.toLowerCase() === 'enter') {
      await validateAndSearch();
    }
  };

  const resetEmptyFields = (formName?: string) => {
    if (!formName || formName === 'identifier') {
      if (!searchByNameForm.getFieldValue('patientSurname')) {
        searchByNameForm.resetFields(['patientSurname']);
      }
    }

    if (!formName || formName === 'name') {
      if (!searchByIdentifierForm.getFieldValue('identifierValue')) {
        searchByIdentifierForm.resetFields(['identifierValue']);
      }
    }
  };

  return (
    <PageLayout title={t('patient-search.title')}>
      <Layout>
        <PageSubHeader title={t('patient-search.title')} />
        <Content className={classes.patientSearchContent}>
          <Row className={classes.noWwrapPatientSearchRow}>
            <Col
              className={classes.inputSearchColumn}
              xs={24}
              lg={8}
              xl={7}
              xxl={5}
            >
              <Card className={classes.noBorder}>
                <Form
                  form={searchByIdentifierForm}
                  layout="vertical"
                  name="searchByIdentifierForm"
                  onChange={async () => handleChange('identifier')}
                  requiredMark={false}
                >
                  <Form.Item
                    data-testid="patient-search--identifier-label"
                    label={t('patient-search.identifier')}
                    name="identifierValue"
                    rules={[
                      {
                        required: true,
                        message: '',
                        pattern: /^[a-zA-Z0-9-]+$/,
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="patient-search--identifier-value-input"
                      placeholder="MRN (e.g. E12345)"
                      size="small"
                      type="text"
                      onKeyDown={handleInputKeyDown}
                    />
                  </Form.Item>
                </Form>
                <Form
                  form={searchByNameForm}
                  layout="vertical"
                  name="searchByNameForm"
                  onChange={async () => handleChange('name')}
                  requiredMark={false}
                >
                  <Form.Item
                    data-testid="patient-search--name-label"
                    label={t('patient-search.first-name')}
                    name="patientGivenName"
                  >
                    <InputBox
                      data-testid="patient-search--given-name-input"
                      placeholder={t('patient-search.first-name')}
                      size="small"
                      type="text"
                      onKeyDown={handleInputKeyDown}
                    />
                  </Form.Item>
                  <Form.Item
                    name="patientSurname"
                    label={t('patient-search.last-name')}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="patient-search--surname-input"
                      placeholder={t('patient-search.last-name')}
                      size="small"
                      type="text"
                      onKeyDown={handleInputKeyDown}
                    />
                  </Form.Item>
                </Form>
                <Button
                  data-testid="patient-search--search-button"
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  onClick={async () => validateAndSearch()}
                  disabled={isSearchDisabled}
                  variant={ButtonVariant.PRIMARY}
                  icon={<Icon component={searchIcon} />}
                  label={isSearching ? 'Searching' : 'Search'}
                />
              </Card>
            </Col>
            <Col className={classes.searchResultColumn}>
              <Row>
                <PatientSearchResults></PatientSearchResults>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PageLayout>
  );
};

export default PatientSearch;
