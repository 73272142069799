// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import type {
  Hl7SystemConfiguration,
  GetHl7SystemConfigurationResponse,
  SaveHl7SystemConfigurationRequest,
  GetHl7RuleConfigurationResponse,
  Hl7RuleConfiguration,
  SaveHl7RuleConfigurationRequest,
  SearchHl7MessagesResponse,
  GetHl7MessageResponse,
  GetJwkSetUrlResponse,
} from '@imprivata-cloud/adminapi-client';
import type moment from 'moment';
import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';

export function saveHl7SystemConfiguration$(
  request: SaveHl7SystemConfigurationRequest,
): Observable<boolean> {
  return promiseToObservable(
    adminApiClient.saveHl7SystemConfiguration(request).finally(() => true),
  );
}

export function getHl7SystemConfiguration$(): Observable<Hl7SystemConfiguration | null> {
  return promiseToObservable(adminApiClient.getHl7SystemConfiguration()).pipe(
    map(
      (response: GetHl7SystemConfigurationResponse) =>
        response.configuration ?? null,
    ),
  );
}

export function deleteHl7SystemConfiguration$(): Observable<boolean> {
  return promiseToObservable(
    adminApiClient.deleteHl7SystemConfiguration().finally(() => true),
  );
}

export function saveHl7RuleConfiguration$(
  request: SaveHl7RuleConfigurationRequest,
): Observable<boolean> {
  return promiseToObservable(
    adminApiClient.saveHl7RuleConfiguration(request).finally(() => true),
  );
}

export function getHl7RuleConfigurationsByTenant$(): Observable<
  Hl7RuleConfiguration[] | null
> {
  return promiseToObservable(adminApiClient.getHl7RuleConfigurations()).pipe(
    map(
      (response: GetHl7RuleConfigurationResponse) =>
        response.ruleConfigurations ?? null,
    ),
  );
}

export function getHl7RuleConfigurationsById$(
  ruleId: string,
): Observable<Hl7RuleConfiguration[] | null> {
  return promiseToObservable(
    adminApiClient.getHl7RuleConfiguration(ruleId),
  ).pipe(
    map(
      (response: GetHl7RuleConfigurationResponse) =>
        response.ruleConfigurations ?? null,
    ),
  );
}

export function deleteHl7RuleConfiguration$(
  ruleId: string,
): Observable<boolean> {
  return promiseToObservable(
    adminApiClient.deleteHl7RuleConfiguration(ruleId).finally(() => true),
  );
}

export function getHl7MessageCount$(
  startDate: moment.Moment | undefined,
  endDate: moment.Moment | null | undefined,
  messageStatus: string[] | null | undefined,
): Observable<number | null> {
  return searchHl7Messages$(startDate, endDate, messageStatus, 1, 1).pipe(
    map((response: SearchHl7MessagesResponse) => response.totalMessages ?? 0),
  );
}

export function searchHl7Messages$(
  startDate: moment.Moment | undefined,
  endDate: moment.Moment | null | undefined,
  messageStatus: string[] | null | undefined,
  pageNumber: number,
  pageSize: number,
  patientId: string | null = null,
): Observable<SearchHl7MessagesResponse> {
  return promiseToObservable(
    adminApiClient.searchHl7Messages(
      startDate,
      endDate,
      messageStatus,
      pageNumber,
      pageSize,
      patientId,
    ),
  );
}

export function getHl7Message$(id: string): Observable<GetHl7MessageResponse> {
  return promiseToObservable(adminApiClient.getHl7Message(id));
}

export async function getJwkSetUrl$(): Promise<GetJwkSetUrlResponse> {
  return adminApiClient.getJwkSetUrl();
}
