// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { PageHeader } from 'antd';

import classes from './PageSubHeader.module.less';

type Props = React.ComponentProps<typeof PageHeader>;

const PageSubHeader: React.FC<Props> = function PageSubHeader({
  className,
  ...rest
}) {
  return (
    <PageHeader className={clsx(className, classes.pageSubHeader)} {...rest} />
  );
};

export default PageSubHeader;
