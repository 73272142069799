// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { TenantType } from '@imprivata-cloud/adminapi-client';
import { deleteHeader, setHeader } from '../../api/api';
import { Headers } from '../../api/constants';
import { StorageKeys } from './constants';
import { saveToStorage, removeFromStorage } from '../../utils';

export const saveTenantId = (tenantId: string) => {
  saveToStorage(StorageKeys.TENANT_ID, tenantId);
  setHeader(Headers.ImprTenantId, tenantId);
};

export const clearTenantId = () => {
  removeFromStorage(StorageKeys.TENANT_ID);
  deleteHeader(Headers.ImprTenantId);
};

export const saveHasValidSession = () => {
  saveToStorage(StorageKeys.HAS_VALID_SESSION, 'true');
};

export const clearHasValidSession = () => {
  removeFromStorage(StorageKeys.HAS_VALID_SESSION);
};

export const saveTenantType = (type: TenantType | null) => {
  if (type) {
    saveToStorage(StorageKeys.TENANT_TYPE, type);
  }
};

export const clearTenantType = () => {
  removeFromStorage(StorageKeys.TENANT_TYPE);
};

export const saveUsername = (username: string) => {
  saveToStorage(StorageKeys.USERNAME, username);
};

export const clearUsername = () => {
  removeFromStorage(StorageKeys.USERNAME);
};
