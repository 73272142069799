// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Dispatch } from 'react';
import {
  minimumAgeConfigurationSaveActions,
} from './actions';

export const saveMinimumAgeConfiguration = (
  settingId: string,
  newMinimumAge: number,
  dispatch: Dispatch<any>,
): void => {
  dispatch(
    minimumAgeConfigurationSaveActions.request({ settingId, newMinimumAge }),
  );
};