// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { RootState } from '../../../../../store/rootReducer';
import { MinimumAgeConfiguration, MinimumAgeRangeConstants } from '../types';

export const isLoadingSelector = ({ ageLimitConfiguration }: RootState): boolean =>
  ageLimitConfiguration.loading;

export const minimumAgeConfigurationSelector = ({
  ageLimitConfiguration,
}: RootState): MinimumAgeConfiguration | null => ageLimitConfiguration.minimumAgeConfiguration;

export const minimumAgeRangeConstantsSelector = ({
  ageLimitConfiguration,
}: RootState): MinimumAgeRangeConstants | null => ageLimitConfiguration.minimumAgeConstants;

export const errorSelector = ({
  ageLimitConfiguration,
}: RootState): string | null => ageLimitConfiguration.error;
