// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/page-layout/PageLayout';
import SetTitle from '../../utils/DynamicTitleHelper';
import DashboardView from './components/dashboard-view/DashboardView';
import PageSubHeader from '../../components/page-sub-header/PageSubHeader';
import ContentCard from '../../components/content-card/ContentCard';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.dashboard'));

  return (
    <PageLayout
      title={t('navigation.dashboard')}
      data-testid="dashboard-page-layout"
      className="overflow-hidden"
    >
      <PageSubHeader title={t('navigation.dashboard')} />
      <ContentCard>
        <DashboardView />
      </ContentCard>
    </PageLayout>
  );
};

export default Dashboard;
