// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { minimumAgeConfigurationGetActions } from './actions';
import {
  isLoadingSelector,
  minimumAgeConfigurationSelector,
  errorSelector,
  minimumAgeRangeConstantsSelector,
} from './selectors';

export const useGetMinimumAgeConfiguration = (): {
  minimumAgeConfiguration: ReturnType<typeof minimumAgeConfigurationSelector>;
  minimumAgeRangeConstants: ReturnType<typeof minimumAgeRangeConstantsSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
  error: ReturnType<typeof errorSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const minimumAgeConfiguration = useSelector(minimumAgeConfigurationSelector);
  const minimumAgeRangeConstants = useSelector(
    minimumAgeRangeConstantsSelector,
  );
  const error = useSelector(errorSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(minimumAgeConfigurationGetActions.request());

      return () => {
        dispatch(minimumAgeConfigurationGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return {
    minimumAgeConfiguration,
    isLoading: loading,
    error,
    minimumAgeRangeConstants,
  };
};
