// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React from 'react';
import clsx from 'clsx';
import { Button, Col, Layout, Row, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import ImprivataLogo from '../../assets/icons/imprivata.svg?react';
import SolidQuestionMarkSvg from '../../assets/svg/solid-question.svg';
import SettingsSvg from '../../assets/svg/settings.svg';
import ProfileSvg from '../../assets/svg/profile.svg';
import RoundedDownArrowSvg from '../../assets/svg/rounded-down-arrow.svg';

import classes from './Header.module.less';
import {
  tenantTypeSelector,
  usernameSelector,
} from '../../containers/login/store/selectors';
import { MenuItemKeys } from '../nav-menu/MenuItemKeys';
import { getPathWithQuery, LinkWithQuery } from '../../utils/routingHelpers';
import { HELP_ROUTE, SETTINGS_ROUTE } from '../../routers/route-names';
import { Link } from 'react-router-dom';
import { TenantType } from '@imprivata-cloud/adminapi-client';
import { useTranslation } from 'react-i18next';

export interface HeaderProps {
  className?: string;
  handleItemClick: (key: string) => void;
}

const Header: React.FC<HeaderProps> = ({
  className,
  handleItemClick,
  ...rest
}) => {
  const { t } = useTranslation();

  const username = useSelector(usernameSelector, () => true);
  const tenantType = useSelector(tenantTypeSelector);

  const items = [
    {
      className: classes.dropdownItem,
      label: (
        <button className={classes.dropdownItemButton}>
          {t('header.logout')}
        </button>
      ),
      key: MenuItemKeys.Logout,
      onclick: () => {
        return handleItemClick(MenuItemKeys.Logout);
      },
    },
  ];

  return (
    <Layout.Header className={clsx(classes.header, className)} {...rest}>
      <Layout className={clsx(classes.section, className)}>
        <Row
          justify="space-between"
          style={{ margin: 'auto 0 auto 0' }}
          align={'middle'}
        >
          <Col flex="none">
            <LinkWithQuery
              to="/"
              className={clsx(classes.imprivataLogoLink, classes.headerItem)}
            >
              <ImprivataLogo data-testid="imprivata-logo" />
            </LinkWithQuery>
          </Col>

          <Col className={classes.rightSection}>
            {tenantType === TenantType.Test && (
              <div data-testid="header-chip" className={classes.chip}>
                {t('header.test-tenant-chip')}
              </div>
            )}
            <Link
              data-testid="help-link"
              to={getPathWithQuery(HELP_ROUTE)}
              className={classes.headerItem}
            >
              <img
                src={SolidQuestionMarkSvg}
                className={classes.glyphHeader}
                alt="Help"
              />
            </Link>
            <Link
              data-testid="settings-link"
              to={getPathWithQuery(SETTINGS_ROUTE)}
              className={classes.headerItem}
            >
              <img
                src={SettingsSvg}
                className={classes.glyphHeader}
                alt="Settings"
              />
            </Link>
            <Dropdown
              menu={{ items, onClick: item => handleItemClick(item.key) }}
              trigger={['click', 'hover']}
            >
              <Button
                data-testid="profile-button"
                className={clsx(classes.dropdownButton, classes.headerItem)}
              >
                <img src={ProfileSvg} alt="Profile" />
                <span>{username}</span>
                <img
                  src={RoundedDownArrowSvg}
                  alt="Open user menu"
                  className={classes.downArrow}
                />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Layout>
    </Layout.Header>
  );
};

export default Header;
