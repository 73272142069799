// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import type { GetMinimumAgeResponse } from '@imprivata-cloud/adminapi-client';
import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';

export function saveMinimumAgeConfiguration$(
  settingId: string,
  newMinimumAge: number,
): Observable<void> {
  return promiseToObservable(
    adminApiClient.saveMinimumAgeConfig({ settingId, newMinimumAge }),
  );
}

export function getMinimumAgeConfiguration$(): Observable<GetMinimumAgeResponse> {
  return promiseToObservable(adminApiClient.getMinimumAgeConfig()).pipe(
    map((response: GetMinimumAgeResponse) => response),
  );
}
