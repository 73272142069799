// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Radio, Row, Spin } from 'antd';
import type { FhirConfiguration, FhirSystemType } from '@imprivata-cloud/adminapi-client';
import { InputBox } from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import PageSubHeader from '../../../../components/page-sub-header/PageSubHeader';
import CancelButton from '../../../../components/action-bar/CancelButton';
import SaveButton from '../../../../components/action-bar/SaveButton';
import SaveDiscardModal from '../../../../components/save-discard-modal/SaveDiscardModal';
import { useGetFhirConfiguration } from './store/hooks';
import classes from './FhirConfigurationContainer.module.less';
import { saveFhirConfiguration } from './store/facades';
import SetTitle from '../../../../utils/DynamicTitleHelper';
import { URL_REGEX } from '../../constants';
import ContentCard from '../../../../components/content-card/ContentCard';

let isDirty: boolean;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const requiredMsgKey = 'fhir-configuration.required';
const invalidUrlMsgKey = 'fhir-configuration.invalid-url';

const isEpicSystemType = (type: FhirSystemType | undefined) => {
  return (type == ("1" as FhirSystemType));
};

const getBasePathFromData = (data?: string): string => {
  return data?.replace("/api/FHIR/R4", "") ?? "";
};

const getApiBasePath = (formData: FhirConfiguration): string => {
  // Handle non-Epic system type
  if (!isEpicSystemType(formData.type)) {
    return formData.audience ?? formData.issuer ?? "";
  }

  // For Epic system types, return the base path from audience or issuer
  return getBasePathFromData(formData.audience) || getBasePathFromData(formData.issuer) || "";
};

const setConfigurationFormData = (formData: FhirConfiguration | null) => {
  let parsedUrl = "";

  if(formData != null){
    parsedUrl = getApiBasePath(formData);
  }

  return {
    clientIdLabel: formData?.clientId,
    identifierSystemLabel: formData?.identifierSystem,
    typeLabel: formData?.type,
    interconnectInstanceBaseURLLabel: parsedUrl,
  };
};

const FhirConfigurationComponent: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  SetTitle(t('fhir-configuration.title'));

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [reset, setReset] = useState(false);

  const { configuration, isLoading } = useGetFhirConfiguration();

  const validateAndUpdate = () => {
    isDirty = false;

    let interconnectInstanceBaseURLValue = (form.getFieldValue('interconnectInstanceBaseURLLabel') || '').trim();
    const clientIdValue = (form.getFieldValue('clientIdLabel') || '').trim();
    const identifierSystemValue = (form.getFieldValue('identifierSystemLabel') || '').trim();
    const typeValue = form.getFieldValue('typeLabel') || 0;

    interconnectInstanceBaseURLValue = interconnectInstanceBaseURLValue.endsWith("/") && typeValue == ("1" as FhirSystemType)
                          ? interconnectInstanceBaseURLValue.substring(0, interconnectInstanceBaseURLValue.length - 1) : interconnectInstanceBaseURLValue;

    let r4ApiRelativePath = isEpicSystemType(typeValue) ? "/api/FHIR/R4" : "";
    let fullUrlWithR4ApiRelativePath = interconnectInstanceBaseURLValue + r4ApiRelativePath;
    
    form.setFieldsValue({
      interconnectInstanceBaseURLLabel: interconnectInstanceBaseURLValue,
      clientIdLabel: clientIdValue,
      identifierSystemLabel: identifierSystemValue,
      typeLabel: typeValue,
    });

    form.validateFields().then(() => {
      saveFhirConfiguration(
        {
          audience: fullUrlWithR4ApiRelativePath,
          clientId: clientIdValue,
          issuer: fullUrlWithR4ApiRelativePath,
          identifierSystem: identifierSystemValue,
          type: typeValue,
          epicRestApiBaseAddress: interconnectInstanceBaseURLValue,
        },
        dispatch,
      );

      setIsSaveDisabled(true);
      setIsModalVisible(false);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      form.setFieldsValue(setConfigurationFormData(configuration));
      setReset(!reset);
    }

    setIsSaveDisabled(true);
    isDirty = false;
    setIsModalVisible(false);
  };

  const handleChange = () => {
    setIsSaveDisabled(false);

    form.validateFields().catch(() => {
      setIsSaveDisabled(true);
    });

    isDirty = true;
  };

  useEffect(() => {
    return () => {
      if (isDirty) {
        setIsModalVisible(true);
      }
    };
  });

  useEffect(() => {
    form.setFieldsValue(setConfigurationFormData(configuration));
  }, [configuration, form]);


  return (
    <>
      <PageSubHeader
        title={t('fhir-configuration.title')}
        extra={[
          <CancelButton
            onClick={() => resetForm()}
            disabled={!isDirty}
            key="cancel-button"
          />,
          <SaveButton
            onClick={() => validateAndUpdate()}
            disabled={isSaveDisabled}
            key="save-button"
          />,
        ]}
      />
      <SaveDiscardModal
        title={t('fhir-configuration.save-discard-modal.title')}
        cancelText={t('fhir-configuration.save-discard-modal.discard')}
        okText={t('actions.save')}
        content={t('fhir-configuration.save-discard-modal.content')}
        open={isModalVisible}
        onSave={() => validateAndUpdate()}
        onDiscard={() => resetForm()}
      ></SaveDiscardModal>
      {isLoading ? (
        <div className={classes.spinner}>
          <Spin size="large" />
        </div>
      ) : (
        <ContentCard>
          <Form
            {...formItemLayout}
            form={form}
            layout="vertical"
            name="register"
            onChange={handleChange}
            className={classes.fhirConfigurationForm}
          >
            <div>
              <Row gutter={10} wrap={true}>
                <Col flex="1">
                  <Form.Item
                    data-testid="fhir-configuration-form--interconnect-instance-base-url-label"
                    name="interconnectInstanceBaseURLLabel"
                    label={t('fhir-configuration.interconnect-instance-base-url')}
                    rules={[
                      {
                        required: true,
                        message: t(requiredMsgKey),
                      },
                      {
                        pattern: URL_REGEX,
                        message: t(invalidUrlMsgKey),
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="fhir-configuration--interconnect-instance-base-url"
                      className={classes.input}
                      size="small"
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} wrap={true}>
                <Col flex="1">
                  <Form.Item
                    data-testid="fhir-configuration-form--client-id-label"
                    name="clientIdLabel"
                    label={t('fhir-configuration.client-id')}
                    rules={[
                      {
                        required: true,
                        message: t(requiredMsgKey),
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="fhir-configuration--client-id"
                      className={classes.input}
                      size="small"
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} wrap={true}>
                <Col flex="1">
                  <Form.Item
                    data-testid="fhir-configuration-form--identifier-system-label"
                    name="identifierSystemLabel"
                    label={t('fhir-configuration.identifier-system')}
                    rules={[
                      {
                        required: true,
                        message: t(requiredMsgKey),
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="fhir-configuration--identifier-system"
                      placeholder="urn:oid:1.1.111"
                      className={classes.input}
                      size="small"
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} wrap={true}>
                <Col flex="1">
                  <Form.Item
                    data-testid="fhir-configuration-form--type-label"
                    name="typeLabel"
                    label={t('fhir-configuration.type')}
                  >
                    <Radio.Group size="small">
                      <Radio
                        data-testid="fhir-configuration--type-generic"
                        value={0}
                      >
                        Generic
                      </Radio>
                      <Radio
                        data-testid="fhir-configuration--type-epic"
                        value={1}
                      >
                        Epic
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex="1"></Col>
              </Row>
            </div>
          </Form>
        </ContentCard>
      )}
    </>
  );
};

export default FhirConfigurationComponent;
