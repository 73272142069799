// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/page-layout/PageLayout';
import IntegrationsContextProvider from './components/integrations-context-provider/IntegrationsContextProvider';
import IntegrationsRoutes from './IntegrationsRoutes';

import classes from './Integrations.module.less';
import SetTitle from '../../utils/DynamicTitleHelper';
import pageLayoutClasses from '../../components/page-layout/PageLayout.module.less';

const { Content, Sider } = Layout;

const Integrations: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.integrations'));

  return (
    <PageLayout
      title={t('navigation.integrations')}
      className={classes.integrations}
      contentClassName={classes.content}
    >
      <IntegrationsContextProvider>
        <Layout className={classes.layout}>
          <Content className={pageLayoutClasses.pageContent}>
            <IntegrationsRoutes />
          </Content>
        </Layout>
      </IntegrationsContextProvider>
    </PageLayout>
  );
};

export default Integrations;
