// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { combineReducers } from 'redux';
import { dashboardReducer as dashboard } from '../containers/dashboard/store/reducers';
import { ageLimitConfigurationReducer as ageLimitConfiguration} from '../containers/settings/containers/age-limit-configuration/store/reducers';
import { fhirConfigurationReducer as fhirConfiguration } from '../containers/integrations/containers/fhir-configuration/store/reducers';
import {
  hl7ConfigurationReducer as hl7Configuration,
  hl7MessageSearchReducer as hl7MessageSearch,
} from '../containers/integrations/containers/hl7-configuration/store/reducers';
import {
  loginReducer as login,
  rehydrate as loginRehydrate,
} from '../containers/login/store/reducers';
import { patientSearchReducer as patientSearch } from '../containers/patient-search/store/reducers';
import { patientDetailsReducer as patientDetails } from '../containers/patient-details/store/reducers';
import { reportsReducer as reports } from '../containers/reports/store/reducers';
import { errorBannerReducer as errorBanner } from './error-banner-state/reducers';

const rootReducer = combineReducers({
  dashboard,
  ageLimitConfiguration,
  fhirConfiguration,
  hl7Configuration,
  hl7MessageSearch,
  login,
  patientSearch,
  patientDetails,
  reports,
  errorBanner,
});

export function rehydrate(): Partial<RootState> {
  // retrieve auth info from storage
  return {
    login: loginRehydrate(),
  };
}

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
