// Copyright 2022, Imprivata, Inc.  All rights reserved.

import shortUUID from 'short-uuid';
import type { RawSpanAttributes } from '@imprivata-cloud/common';
import { Tracer, TracingSolutionType } from '@imprivata-cloud/common';
import { SpanStatusCode } from '@opentelemetry/api';
import type { Span } from '@opentelemetry/api';
import getConfig from '../appConfigUtils';
import { SpanNames } from './constants';
export * from './constants';

const { OTLP_TRACING_URL, TRACING_SOLUTION_TYPE } = getConfig();
const tracingSolutionType = TracingSolutionType[TRACING_SOLUTION_TYPE as keyof typeof TracingSolutionType];

const generateWorkflowId = () => shortUUID.uuid();

export const workflowId = generateWorkflowId();

export const tracer = new Tracer(
  'psadminui',
  workflowId, 
  {
    otlpExportUrl: OTLP_TRACING_URL,
    logToConsole: !(process.env.NODE_ENV === 'production')
  },
  undefined,
  ...(tracingSolutionType !== undefined ? [tracingSolutionType] : [])
);

const spanStore = tracer._testing.getSpansStore();

export function startRootSpan(attributes?: RawSpanAttributes): void {
  if (!tracer.doesSpanExist(SpanNames.ROOT_SPAN)) {
    tracer.startSpan(SpanNames.ROOT_SPAN, attributes);
  }
}

export function doesSpanExistInStore(name: string) {
  return spanStore.has(name);
}

export function endRootSpan(attributes?: RawSpanAttributes): void {
  // end root span if it's the only span in the store
  // (if there are no other traces that are using it as their parent)
  // assumption: all generated traces will have the root span as their parent
  if (spanStore.size === 1 && doesSpanExistInStore(SpanNames.ROOT_SPAN)) {
    tracer.endSpan(SpanNames.ROOT_SPAN, attributes);
  }
}

export function getSpanFromStore(name: string): Span | undefined {
  return spanStore.get(name);
}

export function logSpanNotFoundError(name: string): void {
  console.error(`span "${name}" not found`);
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function recordException(name: string, ex: any): void {
  const span = getSpanFromStore(name);

  // The error message can have any of the following shapes:
  // ex, ex.response, ex.message, ex.response.message, ex.response.Message
  try {
    if (ex?.response) {
      if (JSON.parse(ex.response)?.message) {
        ex = JSON.parse(ex.response).message;
      } else if (JSON.parse(ex.response)?.Message) {
        ex = JSON.parse(ex.response).Message;
      }
    } else if (ex?.message) {
      ex = ex.message;
    }
  } catch (err) {
    if (ex?.response) {
      ex = ex.response;
    } else if (ex?.message) {
      ex = ex.message;
    }
  }

  if (span) {
    span.setStatus({
      code: SpanStatusCode.ERROR,
    });
    span.recordException(ex);
  } else {
    logSpanNotFoundError(name);
  }
}
