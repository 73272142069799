// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Col, Row, Space, Typography, Dropdown, Button } from 'antd';
import Icon from '@ant-design/icons';
import type { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import classes from './PaginationControls.module.less';
import smallDownArrow from '../../../../assets/svg/arrow-60-down.svg?react';
import rightArrow from '../../../../assets/svg/right-arrow.svg?react';
import leftArrow from '../../../../assets/svg/left-arrow.svg?react';

interface PaginationControlsProps {
  pageSizes: MenuItemType[];
  currentCount: {
    totalCount: number;
    pageCount: number;
    startCount: number;
    endCount: number;
  };
  pageSize: number;
  pageNumber: number;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  pageSize,
  pageSizes,
  currentCount,
  pageNumber,
  goToPreviousPage,
  goToNextPage,
}) => (
  <Col flex="auto">
    <Row justify="end">
      <Col>
        <Space size={24}>
          <Space>
            <Typography.Text>Rows per page:</Typography.Text>
            <Dropdown menu={{ items: pageSizes }}>
              <Space size={4}>
                {pageSize}
                <Icon component={smallDownArrow} />
              </Space>
            </Dropdown>
          </Space>
          <Space size={4}>
            <span>
              <span>{currentCount.startCount}</span>
              <span>-</span>
              <span>{currentCount.endCount}</span>
            </span>
            <span>of</span>
            <span>{currentCount.totalCount}</span>
          </Space>
          <Space size={4}>
            <span>{currentCount.totalCount === 0 ? 0 : pageNumber}</span>
            <span>of</span>
            <span>{currentCount.pageCount}</span>
          </Space>
          <Space size={4}>
            <Button
              size="small"
              type="text"
              className={classes.pageButton}
              disabled={pageNumber <= 1}
              onClick={goToPreviousPage}
            >
              <Icon component={leftArrow} />
            </Button>
            <Button
              size="small"
              type="text"
              className={classes.pageButton}
              disabled={currentCount.totalCount <= pageNumber * pageSize}
              onClick={goToNextPage}
            >
              <Icon component={rightArrow} />
            </Button>
          </Space>
        </Space>
      </Col>
    </Row>
  </Col>
);

export default PaginationControls;
