// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import type { ActionButtonProps } from './types';
import saveIcon from '../../assets/svg/saveIcon.svg?react';

const SaveButton: React.FC<ActionButtonProps> = function SaveButton({
  onClick,
  className,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Button
      label={t('actions.save')}
      data-testid="save-button"
      icon={<Icon component={saveIcon} />}
      className={className}
      variant={ButtonVariant.PRIMARY}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default SaveButton;
