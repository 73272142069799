// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Link, useLocation, Route, Redirect } from 'react-router-dom';
import type { ReactElement } from 'react';
import type { LinkProps, RouteProps, RedirectProps } from 'react-router-dom';
import history from '../routers/history';

export type LinkWithQueryProps = LinkProps;

export const LinkWithQuery = ({
  children,
  to,
  ...props
}: LinkWithQueryProps): ReactElement<LinkProps> => {
  let toStr = '';

  if (typeof to === 'string') {
    toStr = to;
  } else if (typeof to === 'object') {
    toStr = to.pathname || '';
  }

  return (
    <Link to={getPathWithQuery(toStr)} {...props}>
      {children}
    </Link>
  );
};

export type RouteWithQueryProps = RouteProps;

export const RouteWithQuery = ({
  children,
  ...props
}: RouteProps): ReactElement<RouteProps> => {
  const { search } = useLocation();

  return (
    <Route path={`${props.path}${search}`} {...props}>
      {children}
    </Route>
  );
};

export const RedirectWithQuery: React.FC<
  RedirectProps & { to: string }
> = props => {
  const { search } = useLocation();
  const currentSearchParams = new URLSearchParams(search);
  const redirectUrl = new URL(props.to, window.location.origin);

  const mergedSearchParams = new URLSearchParams({
    ...Object.fromEntries(currentSearchParams),
    ...Object.fromEntries(redirectUrl.searchParams),
  });

  return (
    <Redirect
      {...props}
      to={`${redirectUrl.pathname}?${mergedSearchParams.toString()}`}
    />
  );
};

export function redirectWithQuery(
  path: string,
  additionalQueryParams: string[] = [],
): void {
  history.push(getPathWithQuery(path, additionalQueryParams));
}

export function getPathWithQuery(
  path: string,
  additionalQueryParams: string[] = [],
  isPatientDetails = false,
): string {
  const currentSearchParams = new URLSearchParams(window.location.search);
  const currentParamKeys = Array.from(currentSearchParams.keys());
  currentParamKeys.forEach(key => {
    if (
      key.toLowerCase() !== 'tenantid' &&
      (key.toLowerCase() !== 'patient' || !isPatientDetails)
    ) {
      currentSearchParams.delete(key);
    }
  });
  const additionalSearchParams = new URLSearchParams(
    additionalQueryParams.join('&'),
  );
  const mergedSearchParams = new URLSearchParams({
    ...Object.fromEntries(currentSearchParams),
    ...Object.fromEntries(additionalSearchParams),
  });

  const searchPath =
    mergedSearchParams.toString().length > 0
      ? `?${mergedSearchParams.toString()}`
      : '';

  return path + searchPath;
}

export function replaceQueryParameters(queryParams: string[] = []) {
  history.replace(getPathWithQuery(window.location.pathname, queryParams));
}
