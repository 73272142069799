// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type {
  ActivityHistoryResponseDecrypted,
  PatientDemographicHistoryDecrypted,
  PatientDecrypted,
  PatientSearchResponseDecrypted,
  PatientHl7MessageSummaryDecrypted,
} from '../../../api/types';
import type { RootState } from '../../../store/rootReducer';

export const isPatientSearchingSelector = ({
  patientSearch,
}: RootState): boolean => patientSearch.searching;

export const isLoadingDemographicsHistorySelector = ({
  patientSearch,
}: RootState): boolean => patientSearch.isLoadingDemographicsHistory;

export const patientSearchResponseSelector = ({
  patientSearch,
}: RootState): PatientSearchResponseDecrypted | null =>
  patientSearch.searchResponse;

export const patientSearchErrorSelector = ({
  patientSearch,
}: RootState): string | null => patientSearch.error;

export const patientSelectedSelector = ({
  patientSearch,
}: RootState): PatientDecrypted | null => patientSearch.selectedPatient;

export const demographicUpdatesSelector = ({
  patientSearch,
}: RootState): PatientDemographicHistoryDecrypted | null =>
  patientSearch.demographicUpdates;
export const patientActivityHistorySelector = ({
  patientSearch,
}: RootState): ActivityHistoryResponseDecrypted | null =>
  patientSearch.activities;

export const patientHl7MessagesSelector = ({
  patientSearch,
}: RootState): PatientHl7MessageSummaryDecrypted[] | null =>
  patientSearch.hl7Messages;

export const isLoadingActivityHistorySelector = ({
  patientSearch,
}: RootState): boolean => patientSearch.isLoadingActivityHistory;

export const isLoadingHl7MessagesSelector = ({
  patientSearch,
}: RootState): boolean => patientSearch.isLoadingHl7Messages;
