// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Button, Card, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './Hl7MessageCountCard.module.less';

const { Text } = Typography;

interface IHl7MessageCountCard {
  count: number;
  color: string;
  description: string;
  timeFrame: string;
  isLoading: boolean;
  onLinkClickAction: () => void;
}

const Hl7MessageCountCard: React.FC<IHl7MessageCountCard> = ({
  count,
  color,
  description,
  timeFrame,
  isLoading,
  onLinkClickAction,
}) => {
  const { t } = useTranslation();

  return (
    <Card loading={isLoading} className={classes.countCard}>
      <Row>
        <Col>
          <Text className={classes.count} style={{ color: color }}>
            {count.toLocaleString()}
          </Text>
        </Col>
      </Row>
      <Row justify={'space-between'}>
        <Col>
          <Text className={classes.description}>
            {description} in the last {timeFrame}
          </Text>
        </Col>
        <Col>
          <Button size="small" type="link" onClick={() => onLinkClickAction()}>
            {t(
              'hl7-configuration.system-configuration.message-count.view-link',
            )}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
export default Hl7MessageCountCard;
