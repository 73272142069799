// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Row } from 'antd';
import Layout from 'antd/lib/layout/layout';
import Link from 'antd/lib/typography/Link';
import SetTitle from '../../utils/DynamicTitleHelper';
import PageLayout from '../../components/page-layout/PageLayout';
import { getTokenizedHelpUrl$ } from '../../api/services/helpService';
import classes from './Help.module.less';
import openNewTabSvgSrc from '../../assets/svg/open-new-tab.svg';
import ContentCard from '../../components/content-card/ContentCard';
import PageSubHeader from '../../components/page-sub-header/PageSubHeader';

const defaultHelpUrl =
  'http://documentation.imprivata.com/00help/BPI/index.htm';

const Help: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.help'));

  const [tokenizedHelpUrl, setTokenizedHelpUrl] =
    useState<string>(defaultHelpUrl);

  const useTokenizedHelpUrlEffect = () =>
    useEffect(() => {
      getTokenizedHelpUrl$().then(response =>
        setTokenizedHelpUrl(response.url ?? defaultHelpUrl),
      );
    }, []);
  useTokenizedHelpUrlEffect();

  return (
    <PageLayout title={t('navigation.help')}>
      <Layout>
        <PageSubHeader title={t('navigation.help')} />
        <ContentCard>
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                title={
                  <Link
                    data-testid="help--support-link"
                    href="http://support.imprivata.com"
                    target="_blank"
                    className={classes.link}
                  >
                    <span>Contact Support</span>
                    <img
                      src={openNewTabSvgSrc}
                      className={classes.openNewTabIcon}
                      alt="Open contact support page icon"
                    />
                  </Link>
                }
                description={
                  <span data-testid="help--support-description">
                    Support and Learning Center
                  </span>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={
                  <Link
                    data-testid="help--help-link"
                    href={tokenizedHelpUrl}
                    target="_blank"
                    className={classes.link}
                  >
                    <span>Online Help</span>
                    <img
                      src={openNewTabSvgSrc}
                      className={classes.openNewTabIcon}
                      alt="Open online help page icon"
                    />
                  </Link>
                }
                description={
                  <span data-testid="help--help-description">
                    Product Documentation
                  </span>
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={<span data-testid="help--about-label">About</span>}
                description={
                  <>
                    <Row data-testid="help--about-product">
                      Imprivata Patient Access
                    </Row>
                    <Row data-testid="help--about-copyright">
                      © 2015 Imprivata, Inc.
                    </Row>
                    <Row data-testid="help--about-rights">
                      All rights reserved.
                    </Row>
                  </>
                }
              />
            </List.Item>
          </List>
        </ContentCard>
      </Layout>
    </PageLayout>
  );
};

export default Help;
