// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Dispatch } from 'react';
import type { PatientDecrypted } from '../../../api/types';
import type { PatientSearchRequestDecrypted } from '../types';
import {
  fetchDemographicsHistoryActions,
  patientActivityHistoryActions,
  patientHl7MessagesActions,
  patientSearchActions,
  selectPatientAction,
} from './actions';
import moment from 'moment';

export const patientSearch = (
  request: PatientSearchRequestDecrypted,
  dispatch: Dispatch<any>,
): void => {
  dispatch(patientSearchActions.request(request));
};

export const patientSelect = (
  patient: PatientDecrypted,
  dispatch: Dispatch<any>,
): void => {
  dispatch(selectPatientAction(patient));
};

export const fetchDemographicsHistory = (
  patientId: string,
  dispatch: Dispatch<any>,
): void => {
  dispatch(fetchDemographicsHistoryActions.request(patientId));
};

export const patientActivityHistory = (
  patientId: string,
  dispatch: Dispatch<any>,
): void => {
  dispatch(patientActivityHistoryActions.request(patientId));
};

export const patientSearchHl7Messages = (
  patientId: string,
  patientCreatedTimestamp: moment.Moment,
  pageNumber: number,
  pageSize: number,
  dispatch: Dispatch<any>,
): void => {
  dispatch(patientHl7MessagesActions.request({patientId: patientId, 
                                              patientCreatedTimestamp: patientCreatedTimestamp, 
                                              pageNumber: pageNumber, 
                                              pageSize: pageSize}));
};
