// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Button, Col, Form, Row, Spin, Tooltip } from 'antd';
import type {
  GetJwkSetUrlResponse,
  Hl7SystemConfiguration,
} from '@imprivata-cloud/adminapi-client';
import { InputBox } from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import Icon from '@ant-design/icons/lib/components/Icon';
import type { FormEvent } from 'react';
import { URL_REGEX } from '../../../../constants';
import PageSubHeader from '../../../../../../components/page-sub-header/PageSubHeader';
import CancelButton from '../../../../../../components/action-bar/CancelButton';
import SaveButton from '../../../../../../components/action-bar/SaveButton';
import SaveDiscardModal from '../../../../../../components/save-discard-modal/SaveDiscardModal';
import { useGetHl7SystemConfiguration } from '../../store/hooks';
import classes from './Hl7SystemConfigurationContainer.module.less';
import {
  saveHl7SystemConfiguration,
  deleteHl7SystemConfiguration,
} from '../../store/facades';

import ConfirmActionModal from '../../../../../../components/confirm-modal/ConfirmActionModal';
import CopyIconSvg from '../../../../../../assets/svg/copy.svg?react';
import { copyToClipboard } from '../../../../../../utils/utils';
import { getJwkSetUrl$ } from '../../../../../../api/services/hl7ConfigurationService';
import {
  endGetJwkSetUrlSpan,
  startGetJwkSetUrlSpan,
} from '../../../../tracing';
import ContentCard from '../../../../../../components/content-card/ContentCard';
import { Link } from 'react-router-dom';
import { getPathWithQuery } from '../../../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../../../routers/route-names';
import SetTitle from '../../../../../../utils/DynamicTitleHelper';

let isDirty: boolean;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const requiredMsgKey = 'hl7-configuration.required';
const invalidUrlMsgKey = 'hl7-configuration.invalid-url';

const setConfigurationFormData = (formData: Hl7SystemConfiguration | null) => {
  return {
    audienceLabel: formData?.audience,
    clientIdLabel: formData?.clientId,
    endpointLabel: formData?.endpoint,
    receivingFacilityLabel: formData?.receivingFacility,
    receivingApplicationLabel: formData?.receivingApplication,
  };
};

const Hl7SystemConfigurationComponent: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  SetTitle(t('hl7-configuration.system-configuration.title'));

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isSaveChangesModalVisible, setIsSaveChangesModalVisible] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [reset, setReset] = useState(false);
  const [jwkSetUrlResponse, setJwkSetUrlResponse] =
    useState<GetJwkSetUrlResponse | null>(null);

  const { systemConfiguration, isLoading } = useGetHl7SystemConfiguration();

  const validateAndUpdate = () => {
    isDirty = false;

    const clientIdValue = (form.getFieldValue('clientIdLabel') || '').trim();
    const endpointValue = (form.getFieldValue('endpointLabel') || '').trim();
    const audienceValue = (form.getFieldValue('audienceLabel') || '').trim();
    const receivingFacilityValue = (
      form.getFieldValue('receivingFacilityLabel') || ''
    ).trim();
    const receivingApplicationValue = (
      form.getFieldValue('receivingApplicationLabel') || ''
    ).trim();

    form.setFieldsValue({
      audienceLabel: audienceValue,
      clientIdLabel: clientIdValue,
      endpointLabel: endpointValue,
      receivingFacilityLabel: receivingFacilityValue,
      receivingApplicationLabel: receivingApplicationValue,
    });

    form.validateFields().then(() => {
      saveHl7SystemConfiguration(
        {
          audience: audienceValue,
          clientId: clientIdValue,
          endpoint: endpointValue,
          receivingFacility: receivingFacilityValue,
          receivingApplication: receivingApplicationValue,
        },
        dispatch,
      );

      setIsSaveDisabled(true);
      setIsSaveChangesModalVisible(false);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      form.setFieldsValue(setConfigurationFormData(systemConfiguration));
      setReset(!reset);
    }

    setIsSaveDisabled(true);
    isDirty = false;
    setIsSaveChangesModalVisible(false);
  };

  const handleChange = () => {
    setIsSaveDisabled(false);

    form.validateFields().catch(() => {
      setIsSaveDisabled(true);
    });

    isDirty = true;
  };

  const copyJwkSetUrl = (e: FormEvent) => {
    if (jwkSetUrlResponse?.jwkSetUrl) {
      copyToClipboard(e, jwkSetUrlResponse.jwkSetUrl);
    }
  };

  useEffect(() => {
    return () => {
      if (isDirty) {
        setIsSaveChangesModalVisible(true);
      }
    };
  });

  useEffect(() => {
    form.setFieldsValue(setConfigurationFormData(systemConfiguration));
  }, [systemConfiguration, form]);

  const useGetJwkSetUrlMountEffect = () => {
    useEffect(() => {
      startGetJwkSetUrlSpan();
      getJwkSetUrl$().then(response => {
        endGetJwkSetUrlSpan();
        setJwkSetUrlResponse(response);
      });
    }, []);
  };
  useGetJwkSetUrlMountEffect();

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Breadcrumb style={{ marginBottom: '10px' }}>
        <Breadcrumb.Item>
          <Link to={getPathWithQuery(integrationsRoutes.HL7_CONFIGURATION)}>
            {t('navigation.hl7-configuration')}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {t('hl7-configuration.system-configuration.title')}
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageSubHeader
        title={t('hl7-configuration.system-configuration.title')}
        extra={[
          <CancelButton
            onClick={() => {
              resetForm();
            }}
            disabled={!isDirty}
            key="cancel-button"
          />,
          <SaveButton
            onClick={() => {
              validateAndUpdate();
            }}
            disabled={isSaveDisabled}
            key="save-button"
          />,
        ]}
      />
      <>
        <SaveDiscardModal
          title={t('hl7-configuration.save-discard-modal.title')}
          cancelText={t('hl7-configuration.save-discard-modal.discard')}
          okText={t('actions.save')}
          content={t('hl7-configuration.save-discard-modal.content')}
          open={isSaveChangesModalVisible}
          onSave={() => {
            validateAndUpdate();
          }}
          onDiscard={() => {
            resetForm();
          }}
        ></SaveDiscardModal>
        <ConfirmActionModal
          title={t(
            'hl7-configuration.system-configuration.delete-system-modal.title',
          )}
          cancelText={t(
            'hl7-configuration.system-configuration.delete-system-modal.cancel-text',
          )}
          okText={t(
            'hl7-configuration.system-configuration.delete-system-modal.affirm-text',
          )}
          content={t(
            'hl7-configuration.system-configuration.delete-system-modal.content',
          )}
          visible={isDeleteModalVisible}
          onSave={() => {
            deleteHl7SystemConfiguration(dispatch);
          }}
          onCancel={() => {
            setIsDeleteModalVisible(false);
          }}
          onClose={() => {
            setIsDeleteModalVisible(false);
          }}
        ></ConfirmActionModal>
      </>
      <ContentCard>
        <Form
          {...formItemLayout}
          form={form}
          layout="vertical"
          name="register"
          onChange={handleChange}
          className={classes.hl7SystemConfigurationForm}
        >
          <div>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="hl7-configuration-form--client-id-label"
                  name="clientIdLabel"
                  label={t('hl7-configuration.system-configuration.client-id')}
                  rules={[
                    {
                      required: true,
                      message: t(requiredMsgKey),
                    },
                  ]}
                >
                  <InputBox
                    data-testid="hl7-configuration--client-id"
                    className={classes.input}
                    size="small"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="hl7-configuration-form--endpoint-label"
                  name="endpointLabel"
                  label={t('hl7-configuration.system-configuration.endpoint')}
                  rules={[
                    {
                      required: true,
                      message: t(requiredMsgKey),
                    },
                    {
                      pattern: URL_REGEX,
                      message: t(invalidUrlMsgKey),
                    },
                  ]}
                >
                  <InputBox
                    data-testid="hl7-configuration--endpoint"
                    className={classes.input}
                    size="small"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="hl7-configuration-form--audience-label"
                name="audienceLabel"
                label={t('hl7-configuration.system-configuration.audience')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                  {
                    pattern: URL_REGEX,
                    message: t(invalidUrlMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="hl7-configuration--audience"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="hl7-configuration-form--receiving-facility-label"
                  name="receivingFacilityLabel"
                  label={t(
                    'hl7-configuration.system-configuration.receiving-facility',
                  )}
                >
                  <InputBox
                    data-testid="hl7-configuration--receiving-facility"
                    className={classes.input}
                    size="small"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Form.Item
                  data-testid="hl7-configuration-form--receiving-application-label"
                  name="receivingApplicationLabel"
                  label={t(
                    'hl7-configuration.system-configuration.receiving-application',
                  )}
                >
                  <InputBox
                    data-testid="hl7-configuration--receiving-application"
                    className={classes.input}
                    size="small"
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10} wrap={true}>
              <Col flex="1">
                <Row gutter={2}>
                  <Col span={6}>
                    {t('hl7-configuration.system-configuration.copy-jwk-url')}
                  </Col>
                  <Col span={2}>
                    <Tooltip title="Copied" trigger={'click'}>
                      <Icon
                        component={CopyIconSvg}
                        data-testid="hl7-jwk-url--copy-icon"
                        className={classes.copyIcon}
                        onClick={e => copyJwkSetUrl(e)}
                      />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    {jwkSetUrlResponse ? jwkSetUrlResponse.jwkSetUrl : ''}
                  </Col>
                </Row>
                <Button
                  type="link"
                  hidden={!systemConfiguration}
                  style={{ paddingLeft: 0 }}
                  onClick={() => {
                    setIsDeleteModalVisible(true);
                  }}
                >
                  {t(
                    'hl7-configuration.system-configuration.delete-system-btn',
                  )}
                </Button>
              </Col>
              <Col flex="1"></Col>
            </Row>
          </div>
        </Form>
      </ContentCard>
    </>
  );
};

export default Hl7SystemConfigurationComponent;
